<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from '@fullcalendar/rrule';
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import { INITIAL_EVENTS, createEventId } from "./event-utils";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import { http } from "@/helpers/easyindustriaapi/config";
import moment from "moment";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import EventMeet from "./event_meet";
import Insert from "./insert";
import { CalendarController } from "../../../controller/CalendarController";

export default {
  props: {
    currentEmpresa: { type: Object },
    vet_pacientes: { type: Array },
    vet_responsible: { type: Array },
    vet_profissional: { type: Array },
    vet_specialtys: { type: Array },
  },
  components: {
    FullCalendar,
    Multiselect,
    EventMeet,
    Insert,
  },
  data() {
    return {
      config: {},
      vtitle:null,
      specialtys: [],
      agenda: {},
      listAgendas: [],
      objeto_geral: null,
      profissionais: [],
      professional: null,
      select_prof: null,
      responsaveis: [],
      select_paciente: null,
      pacientes: [],
      paciente: null,
      modal_edit_del: false,
      vet_env_back: [],
      abr_div: false,
      tp_consulta: null,
      resp: null,
      select_inf: null,
      titulo: null,
      nome: null,
      showModal: false,
      showMeet: false,
      calendarOptions: {
        plugins: [
          rrulePlugin,
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
          bootstrapPlugin,
          listPlugin,
        ],
        locales: [ptBrLocale],
        // timeZone: 'America/Sao_Paulo',
        headerToolbar: {
          left: "prevYear,prev,next,nextYear today",
          center: "title",
          // mostra os botões de mês, semanas dias e listagem dia e mês
          right: "dayGridMonth,dayGridWeek,timeGridDay,listWeek,listDay",
        },
        dayMaxEvents: true,
        droppable: false,
        editable: false,
        eventClick: this.handleEventClick,
        dateClick: this.handleDateClick,
        eventColor: null, // Azul
        eventsSet: this.handleEvents,
        eventDrop: this.handleEventDrop,
        eventResize: this.handleEventResize,
        eventTextColor: "#FFFFFF", // Branco
        initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        events: [],
        initialView: "listDay",
        loading: this.handleLoading,
        navLinks: true, // can click day/week names to navigate views
        select: this.handleDateSelect,
        selectable: true,
        selectMirror: true,
        viewDidMount: this.handleViewDidMount, // Método chamado ao mudar a visualização
        weekends: true,
      },
      currentEvents: [],
      toogleIntructions: false,
      isDisabled: true,
      initialDesc: '',
    };
  },
  created() {
    this.pacientes = this.vet_pacientes;
    this.responsaveis = this.vet_responsible;
    this.profissionais = this.vet_profissional;
    this.specialtys = this.vet_specialtys;

    if (JSON.parse(localStorage.getItem('config')))
    {
      this.config = JSON.parse(localStorage.getItem('config'));
      this.calendarOptions.initialView = this.config.initialView;
    }
  },
  mounted() {
    // this.getData();
    this.fetchEvents();
  },
  computed: {
    axiosParams() {
      const params = new URLSearchParams();
      params.append("empresa_id", this.currentEmpresa.id);

      if (this.paciente) {
        params.append("pacient_id", this.paciente.id);
      }

      if (this.professional) {
        params.append("professional_id", this.professional.id);
      }

      params.append("start", moment(new Date('2024-01-01')).format("YYYY-MM-DD"));
      params.append("end", moment(new Date()).format("YYYY-MM-DD"));

      return params;
    },
    dateAgrement() {
      const date = new Date();
      date.setMinutes(date.getMinutes() + 40);
      return date;
    },
  },
  methods: {
    async fetchEvents() {
      try {
        // this.calendarOptions.events = await CalendarController.loadEvents(this.axiosParams);
        this.listAgendas = await CalendarController.getEvents(this.axiosParams);
        this.calendarOptions.events = CalendarController.eventsResource(this.listAgendas);
        this.abr_div = false;
        setTimeout(() => {
          this.abr_div = true;
          return this.calendarOptions.events;
        }, 100);
      } catch (error) {
        console.error("Erro ao buscar eventos:", error);
      }
    },
    clearAgenda(agenda) {
      agenda.id = null;
      agenda.title = null;
      agenda.start = null;
      agenda.end = null;
      agenda.desc = null;
      agenda.pacient_id = null;
      agenda.professional_id = null;
      agenda.specialty_id = null;
    },
    seleciona_specialty() {
      console.log(this.agenda.specialty);
    },
    // evento para redimensionar o agendas ja existentes
    handleEventResize(eventResizeInfo) {
      // // Lógica para atualizar a duração de um evento ao redimensioná-lo
      // // Atualize o evento no backend ou localmente
      // let objeto_update = {
      //   id: eventResizeInfo.event.id,
      //   title: eventResizeInfo.event.title,
      //   start: this.isCustomDateFormat(eventResizeInfo.event.start)
      //     ? this.convertToDateTimeLocal(eventResizeInfo.event.start)
      //     : eventResizeInfo.event.start,
      //   end: this.isCustomDateFormat(eventResizeInfo.event.end)
      //     ? this.convertToDateTimeLocal(eventResizeInfo.event.end)
      //     : eventResizeInfo.event.end,
      //   empresa_id: eventResizeInfo.event.extendedProps.empresa_id,
      //   desc: eventResizeInfo.event.extendedProps.description,
      //   pacient_id: eventResizeInfo.event.extendedProps.pacient_id,
      //   professional_id: eventResizeInfo.event.extendedProps.professional_id,
      //   specialty_id: eventResizeInfo.event.extendedProps.specialty_id,
      // };
      console.log("eventResizeInfo", eventResizeInfo);
      // console.log(objeto_update);
      // //
      // this.doPut({ ...objeto_update });
    },
    handleEventDrop(info) {
      // let objeto_update = {
      //   id: info.event.id,
      //   title: info.event.title,
      //   start: this.isCustomDateFormat(info.event.start)
      //     ? this.convertToDateTimeLocal(info.event.start)
      //     : info.event.start,
      //   end: this.isCustomDateFormat(info.event.end)
      //     ? this.convertToDateTimeLocal(info.event.end)
      //     : info.event.end,
      //   empresa_id: info.event.extendedProps.empresa_id,
      //   desc: info.event.extendedProps.description,
      //   pacient_id: info.event.extendedProps.pacient_id,
      //   professional_id: info.event.extendedProps.professional_id,
      //   specialty_id: info.event.extendedProps.specialty_id,
      // };

      // // Atualize seu backend ou faça a lógica para ajustar a nova data
      console.log(`Evento movido para: ${info}`);
      // this.doPut({ ...objeto_update });
      // // Aqui você poderia fazer uma chamada para atualizar o evento no banco de dados, por exemplo.
    },
    async doPut(event) {
      // Objeto calendario
      let calendar = {};
      // Adicionando os dados do objeto
      calendar.id              = event.id;
      calendar.desc            = event.desc;
      calendar.empresa_id      = this.currentEmpresa.id;
      calendar.end             = moment(event.end).format("yyyy-MM-DDTHH:mm");
      calendar.professional_id = event.professional_id;
      calendar.specialty_id    = event.specialty_id;
      calendar.pacient_id      = event.pacient_id;
      calendar.start           = moment(event.start).format("yyyy-MM-DDTHH:mm");
      calendar.end             = moment(event.end).format("yyyy-MM-DD");
      calendar.start_recur     = moment(event.start_recur).format("yyyy-MM-DD");
      calendar.end_recur       = moment(event.end_recur).format("yyyy-MM-DD");
      calendar.start_time      = moment(event.start_time).format("HH:mm");
      calendar.end_time        = moment(event.end_time).format("HH:mm");
      calendar.title           = event.title;
      calendar.recurrence      = event.recurrence;

      // Exibe o SweetAlert com loader
      Swal.fire({
        title: "Aguarde...",
        text: "Estamos processando sua solicitação.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        },
      });

      const promisse = http.put(
        `/agenda/${calendar.id}?empresa_id=${this.currentEmpresa.id}`,
        calendar
      );

      return promisse
        .then(() => {
          Swal.close(); // Fecha o loader
          this.showModal = false;
          this.makeToast("success", "Atendimento reagendado com sucesso!");
          this.getData();
        })
        .catch((error) => {
          Swal.close(); // Fecha o loader
          console.log(error);
          // Tratamento de erro 500
          if (error.response && error.response.status === 406) {
            // Exibe mensagem de erro específica para o código 406
            let timerInterval;
            Swal.fire({
              icon: "error",
              title: "Erro de Preenchimento!",
              text: error.response.data,
              timer: 3000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const timer = Swal.getPopup().querySelector("b");
                timerInterval = setInterval(() => {
                  timer.textContent = `${Swal.getTimerLeft()}`;
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                this.showModal = false;
              }
            });
          } else {
            // Tratamento genérico de outros erros
            Swal.fire({
              icon: "error",
              title: "Erro!",
              text: error.response.data.error,
            });
          }
        });
    },
    seleciona_paciente(objeto) {
      let paci = this.pacientes.find((p) => p.id == objeto.target.value);
      this.agenda.titulo = paci.nome;
    },
    seleciona_prof(objeto) {
      let prof = this.profissionais.find((p) => p.id == objeto.target.value);
      this.agenda.titulo = `${prof.name} - ${this.agenda.pacient.nome}`;
      this.agenda.title = `${prof.name} - ${this.agenda.pacient.nome}`;
      this.agenda.professional_id = prof.id;
      this.agenda.professional = prof;
    },
    fechar_modal_edit() {
      this.isDisabled = true; // Desabilita o textarea novamente
      this.clearAgenda(this.agenda);
    },
    handleDateClick(info) {
      // Formatando a data e hora clicada
      const clickedDateTime = {
        date: info.date.toLocaleDateString('pt-BR'),
        time: info.date.toLocaleTimeString('pt-BR'),
        fullDate: info.date
      }

      console.log('Data/Hora clicada:', clickedDateTime)
      this.$emit('date-clicked', clickedDateTime)
    },
    handleViewDidMount(info) {
      this.config = {
        initialView: info.view.type,
      }      
      localStorage.setItem('config', JSON.stringify(this.config));
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    abrir_modal(selectInfo) {
      this.clearAgenda(this.agenda);
      this.select_inf = selectInfo;
      this.showModal = true;
    },
    fechar_modal2() {
      this.showModal = false;
    },
    convertToFullCalendarDate(datetimeLocal) {
      // O formato recebido é "YYYY-MM-DDTHH:MM:SS", então apenas criar o objeto Date
      return new Date(datetimeLocal);
    },
    convertToDateTimeLocal(date) {
      // Converte o objeto Date para o formato "YYYY-MM-DDTHH:MM:SS"
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Adiciona o zero à esquerda se necessário
      const day = ("0" + date.getDate()).slice(-2);
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      const seconds = ("0" + date.getSeconds()).slice(-2);

      // Formata como 'YYYY-MM-DDTHH:MM:SS'
      let hora_tot = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      return hora_tot;
    },
    fechar_modal() {
      let title = this.agenda.titulo;
      let calendarApi = this.select_inf.view.calendar;
      calendarApi.unselect(); // clear date selection

      if (title) {
        calendarApi.addEvent({
          id: createEventId(),
          title,
          start: this.convertToFullCalendarDate(this.agenda.dt_ini),
          end: this.convertToFullCalendarDate(this.agenda.dt_fin),
          extendedProps: {
            description: this.agenda.desc,
            pacient_id: this.agenda.pacient_id,
            professional_id: this.agenda.professional_id,
            specialty_id: this.agenda.specialty,
            // Adicionando empresa_id em extendedProps
          },
        });
      }
      this.vet_env_back = [];
      this.currentEvents.forEach((e) => {
        let objeto = {
          title: e.title,
          start: this.isCustomDateFormat(e.start)
            ? this.convertToDateTimeLocal(e.start)
            : e.start,
          end: this.isCustomDateFormat(e.end)
            ? this.convertToDateTimeLocal(e.end)
            : e.end,
          desc: e.extendedProps.description,
          pacient_id: e.extendedProps.pacient_id,
          professional_id: e.extendedProps.professional_id,
          specialty_id: e.extendedProps.specialty_id,
        };
        this.vet_env_back.push({ ...objeto });
      });

      if (this.vet_env_back.length > 0) {
        let tam = this.vet_env_back.length;
        this.doPost(this.vet_env_back[tam - 1]);
      }
    },
    isCustomDateFormat(dateString) {
      // Verifica se a string corresponde ao formato: Thu Sep 19 2024 00:00:00 GMT-0300 (Horário Padrão de Brasília)
      const regex =
        /^[A-Za-z]{3} [A-Za-z]{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4} \([A-Za-zÀ-ÿ\s]+\)$/;
      const isMatch = regex.test(dateString);

      return isMatch;
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    async getData2() {
      try {
        let response = await http.get(
          "/agenda?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          console.log(response.data);
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            break;
          default:
            this.makeToast("danger", error.message);
            break;
        }
      }
    },
    doMeet(event) {
      // Exibe o SweetAlert com loader
      Swal.fire({
        title: "Aguarde...",
        text: "Estamos processando sua solicitação.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        },
      });
      const promisse = http.post(`/agenda/meet?empresa_id=${this.currentEmpresa.id}`, event);
      return promisse
        .then(() => {
          this.showModal = false;
          this.getData();
          this.makeToast("success", "Reunião gravada com sucesso!");
        })
        .catch((error) => {
          console.log(error);
          let messageErro = error.response;

          switch (messageErro.status) {
            case 406:
              this.makeToast(
                "danger",
                "Erro 406: " + messageErro.data
            )
          }
        })
        .finally(() => {
          Swal.close(); // Fecha o loader
        });
    },
    async doPost(event) {
      this.showModal = false;
      // Objeto calendario
      let calendar = {};
      // Adicionando os dados do objeto
      calendar.desc = event.desc;
      calendar.empresa_id = this.currentEmpresa.id;
      calendar.end = moment(event.end).format("yyyy-MM-DDTHH:mm");
      calendar.professional_id = event.professional_id;
      calendar.pacient_id = event.pacient_id;
      calendar.start = moment(event.start).format("yyyy-MM-DDTHH:mm");
      calendar.title = event.title;
      calendar.specialty_id = event.specialty_id;

      // Exibe o SweetAlert com loader
      Swal.fire({
        title: "Aguarde...",
        text: "Estamos processando sua solicitação.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        },
      });

      const promisse = http.post(
        "/agenda?empresa_id=" + this.currentEmpresa.id,
        calendar
      );
      return promisse
        .then(() => {
          this.successmsg();
          this.getData();
        })
        .catch((error) => {
          Swal.close(); // Fecha o loader
          console.log(error);
          // Tratamento de erro 500
          if (error.response && error.response.status === 406) {
            // Exibe mensagem de erro específica para o código 406
            let timerInterval;
            Swal.fire({
              icon: "error",
              title: "Erro de Preenchimento!",
              text: error.response.data,
              timer: 3000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const timer = Swal.getPopup().querySelector("b");
                timerInterval = setInterval(() => {
                  timer.textContent = `${Swal.getTimerLeft()}`;
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                this.fechar_modal_edit();
                this.showModal = true;
              }
            });
          } else {
            // Tratamento genérico de outros erros
            Swal.fire({
              icon: "error",
              title: "Erro!",
              text: "Houve um erro ao processar sua requisição. Tente novamente.",
            });
          }
        })
        .finally(() => {});
    },
    handleDateSelect(selectInfo) {
      this.abrir_modal(selectInfo);
      const date = new Date(selectInfo.start);
      date.setMinutes(date.getMinutes() + 40);
      this.agenda.dt_fin = this.convertToDateTimeLocal(date);
      this.agenda.dt_ini = this.convertToDateTimeLocal(selectInfo.start);
    },
    doDelete(calendar) {
      // Exibe o SweetAlert com loader
      Swal.fire({
        title: "Aguarde...",
        text: "Estamos processando sua solicitação.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        },
      });
      calendar.empresa_id = this.currentEmpresa.id;
      // Cria a URL com os parâmetros na query string
      const url = `/agenda/${calendar.id}?empresa_id=${this.currentEmpresa.id}`;
      const promisse = http.delete(url);

      return promisse
        .then(() => {
          Swal.close();
          this.fechar_modal_edit();
          this.showMeet = false;
        })
        .catch((error) => {
          let timerInterval;
          Swal.fire({
            icon: "error",
            title: "Erro ao tentar Excluir!",
            text: error.response?.data?.message || "Erro inesperado.",
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const timer = Swal.getPopup().querySelector("b");
              timerInterval = setInterval(() => {
                timer.textContent = `${Swal.getTimerLeft()}`;
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              this.fechar_modal();
              this.showModal = true;
            }
          });
        });
    },
    // evento para editar e exluir
    handleEventClick(info) {
      const evento = info.event;
      this.objeto_geral = info;

      if (evento.extendedProps.meet)
      {
        this.agenda = evento;
        this.showMeet = true;
      } else {        
        const currentAgenda = this.listAgendas.find((agenda) => agenda.id === parseInt(evento.id, 10));
        
        if (currentAgenda)
        {
          const dateEnd = moment(evento.start).format('YYYY-MM-DD');
          evento.end_recur    = moment(dateEnd + ' ' + currentAgenda.end_time).format('YYYY-MM-DDTHH:mm:ss');
        }

        this.agenda.id      = evento.id;
        const recurrence    = evento.extendedProps.recurrences.filter((item) => {
          return moment(item.start).format('YYYY-MM-DD') == moment(evento.start).format('YYYY-MM-DD')
        });
        this.agenda.recurrence = (recurrence && recurrence.length > 0) ? recurrence[0] : null;
        const data_ini = moment(evento.start).format('YYYY-MM-DDTHH:mm:ss');
        const data_fin = (evento.end) ? moment(evento.end).format('YYYY-MM-DDTHH:mm:ss') : evento.end_recur;
        this.agenda.title       = evento.extendedProps.professional.name + " - " + evento.extendedProps.pacient.nome
        this.agenda.dt_ini      = data_ini;
        this.agenda.dt_fin      = data_fin;
        this.agenda.start       = currentAgenda.start;
        this.agenda.end         = currentAgenda.end;
        this.agenda.start_recur = moment(evento.start).format('YYYY-MM-DD');
        this.agenda.end_recur   = moment(evento.end_recur).format('YYYY-MM-DD');
        this.agenda.start_time  = moment(evento.start).format('HH:mm:ss');
        this.agenda.end_time    = moment(evento.end_recur).format('HH:mm:ss');
        this.agenda.specialty   = evento.extendedProps.specialty_id;
        this.select_paciente    = evento.extendedProps.pacients.id;
        this.select_prof        = evento.extendedProps.professional.id;
        this.agenda.desc        = evento ? evento.desc : null;
        this.initialDesc = (this.agenda.recurrence && this.agenda.recurrence.desc) ? this.agenda.recurrence.desc : null;
        this.modal_edit_del = true;
      }
    },
    delete_obj_modal() {
      let objeto = this.objeto_geral; // clickinfo
      let objeto_delt = {
        id: objeto.event.id,
        title: objeto.event.title,
        start: objeto.event.start,
        end: objeto.event.end,
        empresa_id: objeto.event.extendedProps.empresa_id,
        pacient_id: objeto.event.extendedProps.pacient_id,
        professional_id: objeto.event.extendedProps.professional_id,
        specialty_id: objeto.event.extendedProps.specialty_id,
      };

      Swal.fire({
        title: "Deseja realmente excluir o Agendamento?",
        text: "'" + objeto.event.title + "'",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.isConfirmed) {
          this.doDelete({ ...objeto_delt });
          objeto.event.remove();
          Swal.close();
        }
      });
    },
    seleciona_paciente2(objeto) {
      let paci = this.pacientes.find((p) => p.id == objeto);
      this.agenda.titulo = paci.nome;
    },
    seleciona_prof2(objeto) {
      let prof = this.profissionais.find((p) => p.id == objeto);

      if (prof)
      {
        this.agenda.titulo = this.agenda.titulo + "|" + prof.name;
      }
    },
    update_obj_modal() {
      const objeto = this.objeto_geral;
      this.seleciona_prof2(this.select_prof);
      this.seleciona_paciente2(this.select_paciente);
      const newObject = {
        id: objeto.event.id,
        title: this.agenda.titulo2,
        start: this.agenda.dt_ini,
        end: this.agenda.dt_fin,
        empresa_id: objeto.event.extendedProps.empresa_id,
        desc: this.agenda.desc,
        pacient_id: this.select_paciente,
        professional_id: this.select_prof,
        specialty_id: this.agenda.specialty,
        recurrence: this.agenda.recurrence
      };
      this.doPut({ ...newObject });
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    hideIntructions() {
      this.toogleIntructions = !this.toogleIntructions;
    },
    /**
     * Enables the textarea input and stores the initial description value.
     * This method is called when the user clicks the edit button.
     */
    enableTextarea() {
      this.isDisabled = false;
      this.initialDesc = (this.agenda.recurrence && this.agenda.recurrence.desc) ? this.agenda.recurrence.desc : null; // Armazena o valor inicial
      this.$nextTick(() => {
        // Foca no textarea após habilitá-lo
        document.getElementById('agenda-desc').focus();
      });
    },
    changeDesc(e) {
      if (this.agenda.recurrence) {
        this.agenda.recurrence.desc = e.target.value;
      } else {
        this.agenda.recurrence = {
          desc: e.target.value,
          agenda_id: this.agenda.id,
          start: moment(this.agenda.dt_ini).format("yyyy-MM-DD"),
          end: moment(this.agenda.dt_fin).format("yyyy-MM-DD"),
          start_time: moment(this.dt_ini).format("HH:mm"),
          end_time: moment(this.dt_fin).format("HH:mm"),
        };
      }
    },
    /**
     * Show successfull Save Dialog
     */
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Atendimento gravado!",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    handleLoading(isLoading) {
      if (isLoading)
      {
        Swal.fire({
          title: "Aguarde...",
          text: "Estamos processando sua solicitação.",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
          },
        });
      } else {
        Swal.close();
      }
    },
    cancelRecurrence(agenda) {
      this.$emit('doCancelCurrence', agenda);
    }
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-button
              v-b-toggle="'collapse-instructions'"
              class="m-1"
              variant="warning"
              >Instruções</b-button
            >
            <b-button
              v-b-toggle="'collapse-filters'"
              class="m-1"
              variant="primary"
              >Filtros</b-button
            >
            <b-button
              @click="fetchEvents()"
              class="m-1"
              variant="outline-primary"
              >Atualizar</b-button
            >
            <b-button
              @click="abrir_modal(null)"
              class="m-1"
              variant="outline-success"
              >Incluir Reunião</b-button
            >
            <b-collapse id="collapse-instructions" class="mt-1">
              <b-card>
                <div class="demo-app-sidebar-section">
                  <ul>
                    <li>
                      Selecione datas e você será solicitado a criar um novo
                      evento
                    </li>
                    <li>Arraste, solte e redimensione eventos</li>
                    <li>Clique em um evento para excluí-lo</li>
                  </ul>
                </div>
                <div class="demo-app-sidebar-section">
                  <label>
                    <input
                      type="checkbox"
                      :checked="calendarOptions.weekends"
                      @change="handleWeekendsToggle"
                    />
                    Adicionar Finais de Semana
                  </label>
                </div>
              </b-card>
            </b-collapse>

            <b-collapse id="collapse-filters" class="mt-1">
              <b-card>
                <div class="row">
                  <div class="col-md-6">
                    <label for="paciente">Por Pacientes:</label>
                    <Multiselect
                      :options="pacientes"
                      v-model="paciente"
                      :multiple="false"
                      selectLabel="Pressione ENTER para selecionar"
                      deselectLabel="Pressione ENTER para remover"
                      placeholder="Selecione um Paciente"
                      track-by="id"
                      label="nome"
                    >
                    </Multiselect>
                  </div>
                  <div class="col-md-6">
                    <label for="profissional">Por Profissional:</label>
                    <Multiselect
                      id="profissional"
                      :options="vet_profissional"
                      v-model="professional"
                      :multiple="false"
                      selectLabel="Pressione ENTER para selecionar"
                      deselectLabel="Pressione ENTER para remover"
                      placeholder="Selecione um Profissional"
                      track-by="id"
                      label="name"
                    >
                    </Multiselect>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <b-button @click="fetchEvents()" variant="primary"
                      >Filtrar</b-button
                    >
                  </div>
                </div>
              </b-card>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="abr_div">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="app-calendar">
              <FullCalendar
                class="demo-app-calendar"
                :options="calendarOptions"
                :locale="'pt-br'"
              >
                <template v-slot:eventContent="arg">
                  <b>{{ arg.timeText }}</b>
                  <i>{{ arg.event.title }}</i>
                </template>
              </FullCalendar>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- START Modal Insert-->
    <b-modal
      v-model="showModal"
      id="modal-canlendar-insert"
      size="xl"
      :title="'Cadastro Agenda ->  ' + agenda.titulo"
      hide-footer
      @hidden="fechar_modal2()"
    >
      <Insert :agenda="agenda" :pacients="pacientes" :professionals="profissionais" @doMeet="doMeet"/>
    </b-modal>
    <!-- END Modal Insert -->

    <!-- START Modal Edit -->
    <b-modal
      v-model="modal_edit_del"
      id="modal-calendar-edit"
      size="xl"
      :title="agenda.title"
      @hidden="fechar_modal_edit()"
    >
      <div class="row pb-3 mb-3">
        <div class="col-md-6"><span><h4>Atendimento: {{ agenda.atendimento_id }}</h4></span></div>
        <div class="col-md-6"><span><h4>Agendamento: {{ agenda.id }}</h4></span></div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <Label>Paciente</Label>
          <select
            disabled
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="select_paciente"
            @change="seleciona_paciente($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in pacientes"
              :key="index"
              :value="item.id"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <Label>Profissional</Label>
          <select
            disabled
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="select_prof"
            @change="seleciona_prof($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in profissionais"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div v-if="agenda.professional" class="col-md-6">
          <Label>Especialidade</Label>
          <select
            
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="agenda.specialty"
            @change="seleciona_specialty($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in agenda.professional.specialty"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <Label>De:</Label>
          <input
            disabled
            v-model="agenda.dt_ini"
            type="datetime-local"
            class="form-control"
            step="1"
          />
        </div>
        <div class="col-md-3">
          <Label>Até:</Label>
          <input
            disabled
            v-model="agenda.dt_fin"
            type="datetime-local"
            class="form-control"
            step="1"
          />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12">
          <Label>Parecer (descrição): </Label>
          <span
            v-if="isDisabled"
            @click="enableTextarea()"
            class="editable-span"
          >
            {{ (agenda.recurrence && agenda.recurrence.desc) ? agenda.recurrence.desc : 'Clique aqui para editar' }}
          </span>
          <textarea
            id="agenda-desc"
            v-show="!isDisabled"
            v-model="initialDesc"
            @change="changeDesc"
            class="form-control text-left"
            cols="30"
            rows="5"
          ></textarea>
        </div>
      </div>
      <template #modal-footer>
        <b-button
          variant="warning"
          size="md"
          class="float-right"
          @click="cancelRecurrence(agenda)"
        >
          Excluir
        </b-button>
        <!--<b-button
          variant="success"
          size="md"
          class="float-right"
          @click.prevent="update_obj_modal()"
        >
          Gravar
        </b-button>-->
        <b-button
          variant="danger"
          size="md"
          class="float-right"
          @click="$bvModal.hide('modal-calendar-edit')"
        >
          Sair
        </b-button>
        
      </template>
    </b-modal>
    <!-- END Modal Edit -->

    <!-- START Modal Meet -->
    <b-modal 
      v-model="showMeet"
      id="modal-event-meet"
      size="xl"
      :title="agenda.title"
      hide-footer
    >
      <EventMeet :agenda="agenda" :pacients="pacientes" :professionals="profissionais" @doDelete="doDelete"/>
    </b-modal>
  </div>
</template>

<style>
.fc-event,
.fc-event-dot {
  background-color: #ffffff;
}
.editable-span {
  cursor: pointer;
  border-bottom: 1px dashed #ffffff;
  color: #007bff;
}
</style>