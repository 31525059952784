import { http } from '@/helpers/easyindustriaapi/config';
import { RRule } from "rrule";
import moment from "moment";
import Swal from "sweetalert2";

export const CalendarController = {
  lumine(rgb) {
    let color = '#FFFFFF';
      var nib = rgb.split(''); 
      var r = parseInt(nib[1]+nib[2],16);
      var g = parseInt(nib[3]+nib[4],16);
      var b = parseInt(nib[5]+nib[6],16);
      var lumin = ( r * 299 + g * 587 + b * 114) / 1000;
      
      if (lumin > 128) {
        color = '#000000';
      }

      return color;
  },
  eventsResource(events) {
    const processedEvents = [];
    events.forEach(event => {
      let calendar = {
        id: event.id,
          title: event.professional.name + " - " + event.pacient.nome,
          start: moment(event.start).format("yyyy-MM-DD"),
          end: (event.end) ? moment(event.end).format("yyyy-MM-DD") : null,
          startTime: event.start_time,
          endTime: event.end_time,
          daysOfWeek: [event.day_of_week],
          startRecur: event.start_recur,
          endRecur: event.end_recur,
          color: (event.professional.color) ? event.professional.color : "#0000ff", // Cor de fundo
          textColor: (event.professional.color) ? this.lumine(event.professional.color) : '#ffffff', // Cor do texto
          editable: (event.status == 1),
          extendedProps: {
            empresa_id: event.empresa_id,
            professional: event.professional,
            pacients: event.pacient,
            pacient: event.pacient,
            pacient_id: event.pacient_id,
            professional_id: event.professional_id,
            specialty_id: event.specialty_id,
            description: event.desc,
            atendimento_id: event.atendimento_id,
            recurrences: event.recurrences,
            status: event.status
          },
          display: "block",
        };

        // Error in toUpperCase
        if (event.recurrences && Array.isArray(event.recurrences) && event.recurrences.length > 0)
        {
          const excludeDates = event.recurrences
            .filter((recurrence) => recurrence.is_cancelled === 1) // Filtra apenas os cancelados
            .map((recurrence) => {
            return moment(`${recurrence.start}T${recurrence.start_time}`).format('YYYY-MM-DDTHH:mm:ss');
          });
          calendar.exdate = excludeDates;
        }

        // Switch case event.recurrence
        switch (event.recurrence.toUpperCase()) {
          case 'DAILY':
            calendar.rrule = {
              freq: event.recurrence,
              dtstart:  event.start,
              until: moment(event.end_recur).format('YYYY-MM-DD'), // data final da recorrência
            }
            break;
          case 'WEEKLY':
            calendar.rrule = {
              freq: event.recurrence,
              dtstart:  event.start,
              until: moment(event.end_recur).format('YYYY-MM-DD'), // data final da recorrência
            }
            break;
          case 'MONTHLY':
            calendar.rrule = {
              freq: event.recurrence,
              interval: 1,
              dtstart:  event.start,
              until: moment(event.end_recur).format('YYYY-MM-DD'), // data final da recorrência
              bymonthday: parseInt(moment(event.start).format('DD')), // específico para o dia
            }
            break;
          case 'YEARLY':
            calendar.rrule = {
              freq: 'YEARLY',
              interval: 1,
              dtstart: event.start,
              until: moment(event.end_recur).format('YYYY-MM-DD'), // data final da recorrência
              bymonth: parseInt(moment(event.start).format('MM')), // específico para o mês de março
              bymonthday: parseInt(moment(event.start).format('DD')), // específico para o dia 15
            }
            break;
          default: {
            console.log('Default');
          }
        }

        // Test if exist professionals set color and title
        if (event.professionals && event.professionals.length > 0)
          {
            calendar.professionals = event.professionals;
            calendar.color = '#000000';
            calendar.textColor = '#FFFFFF';
            calendar.title = "REUNIÃO - " + event.pacient.nome;
            calendar.meet = true;
          }

        processedEvents.push({ ...calendar });
    });

    return processedEvents;
  },
  async getEvents(axiosParams) {
    Swal.fire({
      title: "Aguarde...",
      text: "Carregando atendimentos da agenda...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
      },
    });

    try {
      const promisse = http.get("/agenda", { params: axiosParams });
      return promisse
        .then((response) => {
          Swal.close();
          return response.data;
        })
        .catch((error) => {
          Swal.close();
          console.log(error);
          Swal.fire({
            title: "Atenção",
            text: "Um erro ocorreu ao carregar os atendimentos: \n" + error.response,
            allowOutsideClick: false,
          });
        })
        .finally(() => {
        });
    } catch (error) {
      console.error("Erro ao buscar eventos:", error);
      return [];
    }
  },

  async loadEvents(axiosParams) {
    try {
      const promisse = http.get("/agenda", { params: axiosParams });
      promisse.then((response) => {
        const data = response.data;
        const processedEvents = [];

        data.forEach(event => {
          // Adicionar o evento principal
          let calendario = {
            id: event.id,
            title: event.professional.name + " - " + event.pacient.nome,
            start: moment(event.start).format("yyyy-MM-DD"),
            end: (event.end) ? moment(event.end).format("yyyy-MM-DD") : null,
            color: event.professional?.color || "#0000ff", // Cor do profissional
            extendedProps: {
              desc: event.desc,
              professional: event.professional,
              pacient: event.pacient,
            },
          };
          
          processedEvents.push({ ...calendario });

          // Adicionar as recorrências
          if (event.recurrence && event.start_recur) {
            const rule = new RRule({
              freq: RRule[event.recurrence], // DAILY, WEEKLY, etc.
              dtstart: new Date(event.start_recur),
              until: event.end_recur ? new Date(event.end_recur) : null,
              byweekday: event.day_of_week !== null ? [event.day_of_week] : null,
            });

            rule.all().forEach(date => {
              // Verificar se há recorrências personalizadas para esta data
              const recurrence = event.recurrences.find(r =>
                new Date(r.start).toISOString().split("T")[0] === date.toISOString().split("T")[0]
              );

              processedEvents.push({
                id: `${event.id}-recurrence-${date.toISOString()}`,
                title: event.title,
                start: `${date.toISOString().split("T")[0]}T${recurrence?.start_time || event.start_time}`,
                end: `${date.toISOString().split("T")[0]}T${recurrence?.end_time || event.end_time}`,
                backgroundColor: event.professional?.color || "#ccc",
                extendedProps: {
                  desc: recurrence?.desc || event.desc,
                  professional: event.professional,
                  pacient: event.pacient,
                },
              });
            });
          }
        });

        return processedEvents;
      });
    } catch (error) {
      console.error("Erro ao carregar os eventos:", error);
      throw error;
    }
  },

  async getProfessionalCalendar(axiosParams) {
    Swal.fire({
      title: "Aguarde...",
      text: "Carregando atendimentos da agenda...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
      },
    });

    try {
      const promisse = http.get("/agenda/profissional", { params: axiosParams });
      return promisse.then((response) => {
        Swal.close();
        return response.data;
      });
    } catch (error) {
      console.error("Erro ao buscar eventos:", error);
      return [];
    }
  },

  eventsProfessionalResource(events) {
    let vet = [...events];
    let vet_aux = [];

    vet.forEach((e) => {
      console.log(e.end);
      let objeto = {
        id: e.id,
        title: e.pacient.nome,
        start: moment(e.start).format("yyyy-MM-DD"),
        end: (e.end) ? moment(e.end).format("yyyy-MM-DD") : null,
        startTime: e.start_time,
        endTime: e.end_time,
        daysOfWeek: [e.day_of_week],
        startRecur: e.start_recur,
        endRecur: e.end_recur,
        color: (e.professional.color) ? e.professional.color : "#0000ff", // Cor de fundo azul
        textColor: (e.professional.color) ? this.lumine(e.professional.color) : '#ffffff', // Texto branco
        extendedProps: {
          empresa_id: e.empresa_id,
          professional: e.professional,
          pacients: e.pacient,
          pacient_id: e.pacient_id,
          professional_id: e.professional_id,
          specialty_id: e.specialty_id,
          description: e.desc,
          atendimento_id: e.atendimento_id,
          recurrences: e.recurrences
        },
        display: "block",
      };

      // Adicionando a rrule para o evento
      if (e.recurrence.toUpperCase() == 'MONTHLY')
      {
        objeto.rrule = {
          freq: e.recurrence,
          interval: 1,
          dtstart:  e.start,
          until: (e.end_recur) ? moment(e.end_recur).format('YYYY-MM-DD') : null, // data final da recorrência
          bymonthday: parseInt(moment(e.start).format('DD')), // específico para o dia 15
        }
      } // end recurrency monthly

      if (e.recurrence.toUpperCase() == 'YEARLY')
      {
        objeto.rrule = {
          freq: 'YEARLY',
          interval: 1,
          dtstart: e.start,
          until: (e.end_recur) ? moment(e.end_recur).format('YYYY-MM-DD') : null, // data final da recorrência
          bymonth: parseInt(moment(e.start).format('MM')), // específico para o mês de março
          bymonthday: parseInt(moment(e.start).format('DD')), // específico para o dia 15
        }
      } // end recurrency year

      if (e.professionals && e.professionals.length > 0)
      {
        objeto.professionals = e.professionals;
        objeto.color = '#000000';
        objeto.textColor = '#FFFFFF';
        objeto.title = "REUNIÃO - " + e.pacient.nome;
        objeto.meet = true;
      }
      vet_aux.push({ ...objeto });
    }); // end foreach

    return [...vet_aux];
  },
};
